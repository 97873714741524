var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDashboard),expression:"showDashboard"}]},[_c('v-card-title',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.showDashboard = !_vm.showDashboard}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("Fechar")])])],1),_c('v-card-text',[_c('v-expand-transition',[_c('v-row',{staticClass:"mb-3 mt-2"},[_c('CardReceiptsMonth',{attrs:{"reload":_vm.refreshDashboards || _vm.filterReceiptMonth,"md":"3"},on:{"onClickedReceiptsMonth":_vm.refreshReceiptMonth}}),_c('CardExpensesMonth',{attrs:{"reload":_vm.refreshDashboards || _vm.filterExpensesMonth,"md":"2"},on:{"onClickedExpensesMonth":_vm.refreshExpensesMonth}}),_c('CardOverdueIncome',{attrs:{"reload":_vm.refreshDashboards || _vm.filterOverdueIncome,"md":"2"},on:{"onClickedOverdueIncome":_vm.refreshOverdueIncome}}),_c('CardDelayedExpense',{attrs:{"reload":_vm.refreshDashboards || _vm.filterDelayedExpense,"md":"2"},on:{"onClickedDelayedExpense":_vm.refreshDelayedExpense}}),_c('CardBalanceEntries',{attrs:{"reload":_vm.refreshDashboards,"md":"3"}})],1)],1)],1)],1),_c('main-container',{attrs:{"icon":_vm.$route.meta.icon,"title":_vm.$route.meta.title},scopedSlots:_vm._u([{key:"tools",fn:function(){return [(_vm.filtered && !_vm.loading)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.getData(true)}}},[_vm._v(" Remover filtro "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-filter-remove-outline ")])],1):_vm._e(),_c('EmcButtonIconCreate',{on:{"update:show":function (value) { return _vm.showForm = value; }}}),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-app-bar',{attrs:{"color":"grey darken-1","dark":"","dense":""}},[_c('v-toolbar-title',{staticClass:"text-subtitle-1"},[_vm._v("Opções")])],1),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{on:{"click":function($event){_vm.showDashboard = !_vm.showDashboard}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":_vm.showDashboard ? "primary" : null}},[_vm._v("mdi-monitor-dashboard")])],1),_c('v-list-item-title',[_vm._v("Resumo do mês")])],1),_c('v-list-item',{on:{"click":function($event){_vm.showGraph = !_vm.showGraph}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-areaspline")])],1),_c('v-list-item-title',[_vm._v("Últimos meses")])],1),_c('v-list-item',{on:{"click":function($event){_vm.showCashForcast = !_vm.showCashForcast}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-areaspline-variant")])],1),_c('v-list-item-title',[_vm._v("Previsão de caixa")])],1)],1)],1)]},proxy:true}])},[(_vm.showFilter)?_c('PostingFilters'):_vm._e(),_c('emc-table-items',{attrs:{"headers":_vm.headers,"params":_vm.params,"collections":_vm.collections,"meta":_vm.meta,"itemDelete":_vm.itemDelete,"itemEdit":_vm.model,"loading":_vm.loading},on:{"update:params":function($event){_vm.params=$event},"update:collections":function($event){_vm.collections=$event},"update:meta":function($event){_vm.meta=$event},"update:itemDelete":function($event){_vm.itemDelete=$event},"update:item-delete":function($event){_vm.itemDelete=$event},"update:itemEdit":function($event){_vm.model=$event},"update:item-edit":function($event){_vm.model=$event}},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('v-switch',{attrs:{"label":"Exibir todos os itens"},model:{value:(_vm.showAllItems),callback:function ($$v) {_vm.showAllItems=$$v},expression:"showAllItems"}})]},proxy:true},{key:"item.is_receipt",fn:function(ref){
var item = ref.item;
return [(item.is_receipt)?_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","label":"","text-color":"white","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-arrow-up-bold-circle ")]),_vm._v(" Receita ")],1):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"red","label":"","text-color":"white","small":""}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-arrow-down-bold-circle ")]),_vm._v(" Despesa ")],1)]}},{key:"item.progress",fn:function(ref){
var item = ref.item;
return [(item.number_end == 1 && item.finished_at)?_c('apexchart',{attrs:{"type":"radialBar","options":_vm.chartOptions7,"series":[100],"width":"80","height":"100"}}):_c('apexchart',{attrs:{"type":"radialBar","options":_vm.chartOptions7,"series":[Math.floor((item.count_payments / item.number_end) * 100)],"width":"80","height":"100"}})]}}])}),_c('FormRegistreFinance',{attrs:{"show":_vm.showForm,"model":_vm.model},on:{"update:show":function (value) { return _vm.showForm = value; },"onCompleted":_vm.onSave}}),_c('GraphItemsFromRecentMonths',{attrs:{"show":_vm.showGraph},on:{"update:show":function (value) { return _vm.showGraph = value; }}}),_c('GraphCastForecast',{attrs:{"show":_vm.showCashForcast},on:{"update:show":function (value) { return _vm.showCashForcast = value; }}}),_c('emc-alert-modal',{attrs:{"text":_vm.text,"show":_vm.show},on:{"update:show":function($event){_vm.show=$event}},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"color":"error","loading":_vm.deleting,"disabled":_vm.deleting},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.deleteItem()}}},[_vm._v("Excluir "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-delete")])],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }